// import Vue from 'vue'
// import Router from 'vue-router'

// Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import Creation from '@/views/creation'

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
      }
    ]
  },
  {
    path: '/Creation',
    component: Creation,
    hidden: true,
    children: [
      {
        path: 'user',
        component: () => import('@/views/creation/user'),
        meta: {
          type: 'add',
          title: '个人中心'
        }
      },
      {
        path: 'console',
        component: () => import('@/views/creation/console')
      },
      {
        path: 'createStep',
        component: () => import('@/views/creation/CreateStep')
      },
      {
        path: 'orderList',
        component: () => import('@/views/creation/OrderList')
      },
      {
        path: 'joinTenant',
        component: () => import('@/views/creation/JoinTenant'),
        meta: {
          noLayout: true
        }
      },
      {
        path: 'renew',
        component: () => import('@/views/creation/renew'),
        meta: {
          noLayout: true
        }
      },
      {
        path: 'handleProduct',
        component: () => import('@/views/creation/renew/handleProduct.vue')
      }
    ]
  },
  {
    path: '/user/login',
    component: () => import('@/views/login/index.vue'),
    hidden: true
  },
  // 老登录页
  /*   {
      path: '/loginNew',
      component: () => import('@/views/login-old'),
      hidden: true
    }, */
  //授权绑定
  {
    path: '/authorizationBinding',
    component: () => import('@/views/authorizationBinding'),
    hidden: true
  },
  //找回密码
  {
    path: '/retrievePW',
    component: () => import('@/views/login/components/retrievePW'),
    hidden: true
  },
  //收银小票模板
  {
    hidden: true,
    path: '/shop/setup/receiptFormat/templateSet',
    component: () => import('@/views/shop/setup/receiptFormat/templateSet.vue'),
    name: 'receiptFormat',
    meta: {
      title: '收银小票模板',
      activeMenu: '/shop/setup/receiptFormat'
    }
  },
  {
    // path: '/system/user-auth',
    // component: Layout,
    hidden: true,
    // permissions: ['O2OMall:config:template'],
    // children: [
    //     {
    path: '/O2OMall/config/template/decoratePage/index',
    component: () =>
      import('@/views/O2OMall/config/template/decoratePage/index'),
    name: 'DecoratePage',
    meta: {
      title: '店铺装修',
      activeMenu: '/O2OMall/config/template/decoratePage'
    }
    // }
    // ]
  },
  {
    path: '/404',
    component: () => import('@/views/error/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/error/401'),
    hidden: true
  },
  {
    path: '/authorCallback',
    component: () => import('@/views/authorCallback/index'),
    hidden: true
  },
  {
    path: '',
    component: Layout,
    redirect: 'index',
    hidden: true,
    children: [
      {
        path: 'index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    path: '/user',
    component: Layout,
    hidden: true,
    redirect: 'noredirect',
    children: [
      {
        path: 'profile',
        component: () => import('@/views/components/userInfo/index.vue'),
        name: 'Profile',
        meta: { title: '个人中心', icon: 'user' }
      }
    ]
  }
]

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
      //生产mes的工序退料
{
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['mes:bill:processBack'],
  children: [
    {
      path: '/mes/bill/processBack/processBackDetail',
      component: () => import('@/views/mes/bill/processBack/processBackDetail'),
      name: 'processBackDetail',
      meta: { title: '工序退料明细', activeMenu: '/mes/bill/processBack' }
    }
  ]
},
      //生产mes的工序领料
 {
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['mes:bill:processLead'],
  children: [
    {
      path: '/mes/bill/processLead/processLeadDetail',
      component: () => import('@/views/mes/bill/processLead/processLeadDetail'),
      name: 'processLeadDetail',
      meta: { title: '工序领料明细', activeMenu: '/mes/bill/processLead' }
    }
  ]
},
      //生产mes的工序报工
 {
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['mes:bill:processReceive'],
  children: [
    {
      path: '/mes/bill/processReceive/processReceiveDetail',
      component: () => import('@/views/mes/bill/processReceive/processReceiveDetail'),
      name: 'processReceiveDetail',
      meta: { title: '工序收货明细', activeMenu: '/mes/bill/processReceive' }
    }
  ]
},
      //生产mes的工序报工
 {
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['mes:bill:processReport'],
  children: [
    {
      path: '/mes/bill/processReport/processReportDetail',
      component: () => import('@/views/mes/bill/processReport/processReportDetail'),
      name: 'processReportDetail',
      meta: { title: '工序报工明细', activeMenu: '/mes/bill/processReport' }
    }
  ]
},
    //生产mes的工序任务
 {
  path: '/system/user-auth',
  component: Layout,
  hidden: true,
  permissions: ['mes:bill:processTask'],
  children: [
    {
      path: '/mes/bill/processTask/processTaskDetail',
      component: () => import('@/views/mes/bill/processTask/processTaskDetail'),
      name: 'processTaskDetail',
      meta: { title: '工序任务明细', activeMenu: '/mes/bill/processTask' }
    }
  ]
},
    //生产mes的生产工单
 {
      path: '/system/user-auth',
      component: Layout,
      hidden: true,
      permissions: ['mes:bill:workOrder'],
      children: [
        {
          path: '/mes/bill/workOrder/workOrderDetail',
          component: () => import('@/views/mes/bill/workOrder/workOrderDetail'),
          name: 'workOrderDetail',
          meta: { title: '生产工单明细', activeMenu: '/mes/bill/workOrder' }
        }
      ]
}, 
  //生产管理的生产组/商品信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:sets:produceGroup'],
    children: [
      {
        path: '/produce/sets/produceGroup/goods',
        component: () => import('@/views/produce/sets/produceGroup/goods'),
        name: 'produceGroupGoods',
        meta: { title: '生产组商品查询', activeMenu: '/produce/sets/produceGroup' }
      }
    ]
  },

  //生产管理的生产入库相关单据
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:storeDistribute'],
    children: [
      {
        path: '/produce/bill/storeDistribute/relevantDocuments',
        component: () => import('@/views/components/relevantDocuments'),
        name: 'produceStoreDistributeRelevant',
        meta: { title: '产品分货单-相关单据', activeMenu: '/produce/bill/storeDistribute' }
      }
    ]
  },

  //生产管理的生产入库相关单据
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:inStore'],
    children: [
      {
        path: '/produce/bill/inStore/relevantDocuments',
        component: () => import('@/views/components/relevantDocuments'),
        name: 'produceInStoreRelevant',
        meta: { title: '产品入库单-相关单据', activeMenu: '/produce/bill/inStore' }
      }
    ]
  },
  //生产管理的生产配料相关单据
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:matchMaterial'],
    children: [
      {
        path: '/produce/bill/matchMaterial/relevantDocuments',
        component: () => import('@/views/components/relevantDocuments'),
        name: 'produceMatchMaterialRelevant',
        meta: { title: '生产配料单-相关单据', activeMenu: '/produce/bill/matchMaterial' }
      }
    ]
  },
  //生产管理的生产领料相关单据
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:leadMaterial'],
    children: [
      {
        path: '/produce/bill/leadMaterial/relevantDocuments',
        component: () => import('@/views/components/relevantDocuments'),
        name: 'produceLeadMaterialRelevant',
        meta: { title: '生产领料单-相关单据', activeMenu: '/produce/bill/leadMaterial' }
      }
    ]
  },
  //生产管理的生产计划单相关单据
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:plan'],
    children: [
      {
        path: '/produce/bill/plan/relevantDocuments',
        component: () => import('@/views/components/relevantDocuments'),
        name: 'producePlanRelevant',
        meta: { title: '生产计划单-相关单据', activeMenu: '/produce/bill/plan' }
      }
    ]
  },
  //门店工商
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:license'],
    children: [
      {
        path: '/shop/base/license/licenseDetail',
        component: () => import('@/views/shop/base/license/licenseDetail'),
        name: 'LicenseDetail',
        meta: { title: '门店工商明细', activeMenu: '/shop/base/license' }
      }
    ]
  },
  //门店调入   加盟店配送退回收货
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['joinClient:bill:moveIn'],
    children: [
      {
        path: '/joinClient/bill/moveIn/moveInDetail',
        component: () => import('@/views/store/bill/moveIn/moveInDetail'),
        name: 'JoinClientStoreMoveInDetail',
        meta: { title: '配送退回收货单明细', activeMenu: '/joinClient/bill/moveIn' }
      }
    ]
  },
  //门店调出   加盟店退回
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['joinClient:bill:shopMoveOut'],
    children: [
      {
        path: '/joinClient/bill/shopMoveOut/shopMoveOutDetail',
        component: () => import('@/views/shop/bill/moveOut/MoveOutDetail'),
        name: 'JoinClientMoveOutDetail',
        meta: { title: '配送退回单明细', activeMenu: '/joinClient/bill/shopMoveOut' }
      }
    ]
  },
  //仓库调出   加盟店配送
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['joinClient:bill:moveOut'],
    children: [
      {
        path: '/joinClient/bill/moveOut/moveOutDetail',
        component: () => import('@/views/store/bill/moveOut/moveOutDetail'),
        name: 'JoinClientStoreMoveOutDetail',
        meta: { title: '配送单明细', activeMenu: '/joinClient/bill/moveOut' }
      }
    ]
  },
  //加盟店原料订货
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['joinClient:bill:materialOrder'],
    children: [
      {
        path: '/joinClient/bill/materialOrder/materialOrderDetail',
        component: () => import('@/views/shop/bill/materialOrder/materialOrderDetail'),
        name: 'JoinClientMaterialOrderDetail',
        meta: {
          title: '加盟店原料订货明细',
          activeMenu: '/joinClient/bill/materialOrder',
        }
      }
    ]
  },
  //加盟店订货
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['joinClient:bill:order'],
    children: [
      {
        path: '/joinClient/bill/order/orderDetail',
        component: () => import('@/views/shop/bill/order/OrderDetail'),
        name: 'JoinClientOrderDetail',
        meta: {
          title: '加盟店订货明细',
          activeMenu: '/joinClient/bill/order',
        }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:vipCard'],
    children: [
      {
        path: 'vip/base/vipCard/vipLedger',
        component: () =>
          import('@/views/vip/base/vipCard/components/vipLedger'),
        name: 'VipLedger',
        meta: {
          title: '会员账本',
          activeMenu: '/vip/base/vipCard'
        }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:vipCard'],
    children: [
      {
        path: 'vip/base/vipCard/scoreBook',
        component: () =>
          import('@/views/vip/base/vipCard/components/scoreBook'),
        name: 'VipScoreBook',
        meta: {
          title: '会员积分账本',
          activeMenu: '/vip/base/vipCard'
        }
      }
    ]
  },
  //抖音门店新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:douyin:shop'],
    children: [
      {
        path: '/O2OThird/douyin/shop/douyinShopDetail',
        component: () =>
          import('@/views/O2OThird/douyin/shop/douyinShopDetail'),
        name: 'DouyinShopDetail',
        meta: {
          title: '抖音门店明细',
          activeMenu: '/O2OThird/douyin/shop'
        }
      }
    ]
  },
  //抖音券新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:douyin:coupon'],
    children: [
      {
        path: '/O2OThird/douyin/coupon/couponDetail',
        component: () => import('@/views/O2OThird/douyin/coupon/couponDetail'),
        name: 'CouponDetail',
        meta: {
          title: '抖音券明细',
          activeMenu: '/O2OThird/douyin/coupon'
        }
      }
    ]
  },
  //短信服务的查看历史
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:sms:history'],
    children: [
      {
        path: '/marketing/sms/history/historyDetail',
        component: () => import('@/views/marketing/sms/history/historyDetail'),
        name: 'historyDetail',
        meta: {
          title: '查看历史明细',
          activeMenu: '/marketing/sms/history'
        }
      }
    ]
  },
  //裱花生产单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:produce'],
    children: [
      {
        path: 'decoration/bill/produce/ProduceDetail',
        component: () =>
          import('@/views/decoration/bill/produce/ProduceDetail'),
        name: 'decorationProduceDetail',
        meta: {
          title: '裱花生产单明细',
          activeMenu: '/decoration/bill/produce'
        }
      }
    ]
  },
  //现烤生产单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:produce'],
    children: [
      {
        path: 'freshlyBaked/bill/produce/ProduceDetail',
        component: () =>
          import('@/views/freshlyBaked/bill/produce/ProduceDetail'),
        name: 'FreshlyBakedProduceDetail',
        meta: {
          title: '现烤生产单明细',
          activeMenu: '/decoration/bill/produce'
        }
      }
    ]
  },
  // 裱花间商品方案详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:base:goodsCase'],
    children: [
      {
        path: '/decoration/base/goodsCase/goodsCaseDetail',
        component: () =>
          import('@/views/decoration/base/goodsCase/goodsCaseDetail'),
        name: 'goodsCaseDetail',
        meta: {
          title: '裱花间商品方案详情',
          activeMenu: '/decoration/base/goodsCase'
        }
      }
    ]
  },
  // 现烤商品方案详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:base:goodsCase'],
    children: [
      {
        path: '/freshlyBaked/base/goodsCase/goodsCaseDetail',
        component: () =>
          import('@/views/freshlyBaked/base/goodsCase/goodsCaseDetail'),
        name: 'freshlyBakedGoodsCaseDetail',
        meta: {
          title: '现烤间商品方案详情',
          activeMenu: '/freshlyBaked/base/goodsCase'
        }
      }
    ]
  },
  //裱花原料消耗明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:waste'],
    children: [
      {
        path: '/decoration/bill/waste/decorationWasteDetail',
        component: () =>
          import('@/views/decoration/bill/waste/decorationWasteDetail'),
        name: 'decorationWasteDetail',
        meta: {
          title: '裱花原料消耗明细',
          activeMenu: '/decoration/bill/waste'
        }
      }
    ]
  },
  //现烤原料消耗明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:waste'],
    children: [
      {
        path: '/freshlyBaked/bill/waste/decorationWasteDetail',
        component: () =>
          import('@/views/freshlyBaked/bill/waste/decorationWasteDetail'),
        name: 'FreshlyBakedWasteDetail',
        meta: {
          title: '现烤原料消耗明细',
          activeMenu: '/decoration/bill/waste'
        }
      }
    ]
  },
  //门店POS图片
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:setup:posImage'],
    children: [
      {
        path: '/shop/setup/posImage/posImageDetail',
        component: () => import('@/views/shop/setup/posImage/posImageDetail'),
        name: 'posImageDetail',
        meta: {
          title: '门店POS图片明细',
          activeMenu: '/shop/setup/posImage'
        }
      }
    ]
  },

  // 厨打商品方案详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:cookPrintCase'],
    children: [
      {
        path: 'shop/base/cookPrintCase/cookPrintCaseDetail',
        component: () =>
          import('@/views/shop/base/cookPrintCase/cookPrintCaseDetail'),
        name: 'cookPrintCaseDetail',
        meta: {
          title: '厨打商品方案详情',
          activeMenu: '/shop/base/cookPrintCase'
        }
      }
    ]
  },

  //门店收款查询明细详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:report:gatherQuery'],
    children: [
      {
        path: '/shop/report/gatherQuery/gatherQueryDetail',
        component: () =>
          import('@/views/shop/report/gatherQuery/gatherQueryDetail'),
        name: 'gatherQueryDetail',
        meta: {
          title: '门店收款查询明细',
          activeMenu: '/shop/report/gatherQuery'
        }
      }
    ]
  },

  //期初应付款详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:initialData:ap'],
    children: [
      {
        path: '/system/initialData/ap/apDetail',
        component: () => import('@/views/system/initialData/ap/apDetail'),
        name: 'apDetail',
        meta: {
          title: '期初应付款明细',
          activeMenu: '/goods/initialData/ar'
        }
      }
    ]
  },
  // 打印模板详情
  {
    path: '/system/user-auth',
    hidden: true,
    permissions: ['system:custom:printTemplate'],
    component: () =>
      import('@/views/system/custom/printTemplate/detail/index.vue'),
    children: [
      {
        name: 'printTemplateDetail',
        path: '/system/custom/printTemplate/detail',
        hidden: true,
        meta: {
          title: '打印模板详情',
          activeMenu: '/system/custom/printTemplate'
        }
      }
    ]
  },
  //期初应收款详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:initialData:ar'],
    children: [
      {
        path: '/system/initialData/ar/arDetail',
        component: () => import('@/views/system/initialData/ar/arDetail'),
        name: 'arDetail',
        meta: {
          title: '期初应收款明细',
          activeMenu: '/goods/initialData/ar'
        }
      }
    ]
  },
  //期初商品库存详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:initialData:goodsStock'],
    children: [
      {
        path: '/system/initialData/goodsStock/goodsStockDetail',
        component: () =>
          import('@/views/system/initialData/goodsStock/goodsStockDetail'),
        name: 'goodsStockDetail',
        meta: {
          title: '期初商品库存明细',
          activeMenu: '/goods/initialData/goodsStock'
        }
      }
    ]
  },
  //门店业绩详情
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:saleTarget'],
    children: [
      {
        path: '/shop/base/saleTarget/saleTargetDetail',
        component: () =>
          import('@/views/shop/base/saleTarget/saleTargetDetail'),
        name: 'saleTargetDetail',
        meta: {
          title: '门店业绩明细',
          activeMenu: '/shop/base/saleTarget'
        }
      }
    ]
  },
  //Plus会员方案
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:plusVip:case'],
    children: [
      {
        path: '/vip/plusVip/case/caseDetail',
        component: () => import('@/views/vip/plusVip/case/caseDetail'),
        name: 'caseDetail',
        meta: {
          title: 'Plus会员方案明细',
          activeMenu: '/vipcard/plusVip/case'
        }
      }
    ]
  },

  //优惠券券包
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:package'],
    children: [
      {
        path: '/marketing/coupon/package/packageDetail',
        component: () =>
          import('@/views/marketing/coupon/package/packageDetail'),
        name: 'packageDetail',
        meta: {
          title: '优惠券券包明细',
          activeMenu: '/marketing/coupon/package'
        }
      }
    ]
  },
  //导购员提成设置
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:saleGuide:commission'],
    children: [
      {
        path: '/shop/saleGuide/commission/commissionDetail',
        component: () =>
          import('@/views/shop/saleGuide/commission/commissionDetail'),
        name: 'commissionDetail',
        meta: {
          title: '导购员提成设置明细',
          activeMenu: '/shop/saleGuide/commission'
        }
      }
    ]
  },
  //分销员提成设置
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:distribution:commissionCase'],
    children: [
      {
        path: '/vip/distribution/commissionCase/commissionDetail',
        component: () =>
          import('@/views/vip/distribution/commissionCase/commissionDetail'),
        name: 'commissionDetailVip',
        meta: {
          title: '分销员提成设置明细',
          activeMenu: '/vip/distribution/commissionCase'
        }
      }
    ]
  },
  //支付通道设置
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:payChannel'],
    children: [
      {
        path: '/base/payChannel/payChannelDetail',
        component: () =>
          import('@/views/shop/base/payChannel/payChannelDetail'),
        name: 'payChannelDetail',
        meta: { title: '支付通道设置明细', activeMenu: '/shop/base/payChannel' }
      }
    ]
  },
  //商品标签信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:goods:labelTemplate'],
    children: [
      {
        path: '/goods/labelTemplate/labelTemplateDetail',
        component: () =>
          import('@/views/system/goods/labelTemplate/labelTemplateDetail'),
        name: 'labelTemplateDetail',
        meta: {
          title: '商品标签模板明细',
          activeMenu: '/goods/goods/labelTemplate'
        }
      }
    ]
  },
  //优惠券发放
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:takeOut'],
    children: [
      {
        path: '/marketing/coupon/takeOut/takeOutDetail',
        component: () =>
          import('@/views/marketing/coupon/takeOut/takeOutDetail'),
        name: 'takeOutDetail',
        meta: {
          title: '优惠券发放明细',
          activeMenu: '/marketing/coupon/takeOut'
        }
      }
    ]
  },
  //会员升级
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:vip:upgrade'],
    children: [
      {
        path: '/marketing/vip/upgrade/upgradeDetail',
        component: () => import('@/views/marketing/vip/upgrade/upgradeDetail'),
        name: 'upgradeDetail',
        meta: {
          title: '会员升级明细',
          activeMenu: '/marketing/vipMarketing/upgrade'
        }
      }
    ]
  },
  //生日有礼
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:vip:birthdayGift'],
    children: [
      {
        path: '/marketing/vip/birthdayGift/birthdayGiftDetail',
        component: () =>
          import('@/views/marketing/vip/birthdayGift/birthdayGiftDetail'),
        name: 'birthdayGiftDetail',
        meta: {
          title: '生日有礼明细',
          activeMenu: '/marketing/vipMarketing/birthdayGift'
        }
      }
    ]
  },
  //入会有礼
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:vip:joinGift'],
    children: [
      {
        path: '/marketing/vip/joinGift/joinGiftDetail',
        component: () =>
          import('@/views/marketing/vip/joinGift/joinGiftDetail'),
        name: 'joinGiftDetail',
        meta: {
          title: '入会有礼明细',
          activeMenu: '/marketing/vipMarketing/joinGift'
        }
      }
    ]
  },
  //精准营销明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:vip:precision'],
    children: [
      {
        path: '/marketing/vip/precision/precisionDetail',
        component: () =>
          import('@/views/marketing/vip/precision/precisionDetail'),
        name: 'precisionDetail',
        meta: {
          title: '精准营销明细',
          activeMenu: '/marketing/vipMarketing/precision'
        }
      }
    ]
  },
  //商品资料新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:goods:info'],
    children: [
      {
        path: '/system/goods/info/infoAdd',
        component: () => import('@/views/system/goods/info/infoAdd'),
        name: 'GoodsInfoAdd',
        meta: {
          title: '商品资料-新增',
          activeMenu: '/system/goods/info'
        }
      }
    ]
  },
  //商品资料明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:goods:info'],
    children: [
      {
        path: '/system/goods/info/infoDetail',
        component: () => import('@/views/system/goods/info/infoDetail'),
        name: 'GoodsInfoDetail',
        meta: {
          title: '商品资料-编辑',
          activeMenu: '/system/goods/info'
        }
      }
    ]
  },
  //应收其他退款单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:otherReceiptBack'],
    children: [
      {
        path: '/arap/arbill/otherReceiptBack/otherReceiptBackDetail',
        component: () =>
          import('@/views/arap/arbill/otherReceiptBack/otherReceiptBackDetail'),
        name: 'otherReceiptBackDetail',
        meta: {
          title: '应收其他退款单明细',
          activeMenu: '/arap/arbill/otherReceiptBack'
        }
      }
    ]
  },
  //应收其他收入单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:otherReceipt'],
    children: [
      {
        path: '/arap/arbill/otherReceipt/otherReceiptDetail',
        component: () =>
          import('@/views/arap/arbill/otherReceipt/otherReceiptDetail'),
        name: 'otherReceiptDetail',
        meta: {
          title: '应收其他收入单明细',
          activeMenu: '/arap/arbill/otherReceipt'
        }
      }
    ]
  },
  //应收其他支出单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:otherPayment'],
    children: [
      {
        path: '/arap/apbill/otherPayment/otherPaymentDetail',
        component: () =>
          import('@/views/arap/apbill/otherPayment/otherPaymentDetail'),
        name: 'otherPaymentDetail',
        meta: {
          title: '应付其他支出单明细',
          activeMenu: '/arap/apbill/otherPayment'
        }
      }
    ]
  },
  //应收其他支出退款单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:otherPaymentBack'],
    children: [
      {
        path: '/arap/apbill/otherPaymentBack/otherPaymentBackDetail',
        component: () =>
          import('@/views/arap/apbill/otherPaymentBack/otherPaymentBackDetail'),
        name: 'otherPaymentBackDetail',
        meta: {
          title: '应付其他支出退款单明细',
          activeMenu: '/arap/apbill/otherPaymentBack'
        }
      }
    ]
  },
  //应收预退款新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:prepareReceiptBack'],
    children: [
      {
        path: '/arap/arbill/prepareReceiptBack/prepareReceiptBackDetail',
        component: () =>
          import(
            '@/views/arap/arbill/prepareReceiptBack/prepareReceiptBackDetail'
          ),
        name: 'prepareReceiptBackDetail',
        meta: {
          title: '应收预收退款单明细',
          activeMenu: '/arap/arbill/prepareReceiptBack'
        }
      }
    ]
  },
  //应收预收款新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:prepareReceipt'],
    children: [
      {
        path: '/arap/arbill/prepareReceipt/prepareReceiptDetail',
        component: () =>
          import('@/views/arap/arbill/prepareReceipt/prepareReceiptDetail'),
        name: 'prepareReceiptDetail',
        meta: {
          title: '应收预收款单明细',
          activeMenu: '/arap/arbill/prepareReceipt'
        }
      }
    ]
  },
  //应收退款新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:receiptBack'],
    children: [
      {
        path: '/arap/arbill/receiptBack/receiptBackDetail',
        component: () =>
          import('@/views/arap/arbill/receiptBack/receiptBackDetail'),
        name: 'receiptBackDetail',
        meta: {
          title: '应收退款单明细',
          activeMenu: '/arap/arbill/receiptBack'
        }
      }
    ]
  },
  //应收收款新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:arbill:receipt'],
    children: [
      {
        path: '/arap/arbill/receipt/receiptDetail',
        component: () => import('@/views/arap/arbill/receipt/receiptDetail'),
        name: 'receiptDetail',
        meta: {
          title: '应收收款单明细',
          activeMenu: '/arap/arbill/receipt'
        }
      }
    ]
  },
  //付款单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:payment'],
    children: [
      {
        path: '/arap/apbill/payment/paymentDetail',
        component: () => import('@/views/arap/apbill/payment/paymentDetail'),
        name: 'paymentDetail',
        meta: {
          title: '付款单明细',
          activeMenu: '/arap/apbill/payment'
        }
      }
    ]
  },
  //付款单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:paymentBack'],
    children: [
      {
        path: '/arap/apbill/paymentBack/paymentBackDetail',
        component: () =>
          import('@/views/arap/apbill/paymentBack/paymentBackDetail'),
        name: 'paymentBackDetail',
        meta: {
          title: '付款退款明细',
          activeMenu: '/arap/apbill/paymentBack'
        }
      }
    ]
  },
  //预付款
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:preparePayment'],
    children: [
      {
        path: '/arap/apbill/preparePayment/preparePaymentDetail',
        component: () =>
          import('@/views/arap/apbill/preparePayment/preparePaymentDetail'),
        name: 'apbillPrepareReceiptDetail',
        meta: {
          title: '预付款明细',
          activeMenu: '/arap/apbill/preparePayment'
        }
      }
    ]
  },
  //预付款退
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:apbill:preparePaymentBack'],
    children: [
      {
        path: '/arap/apbill/preparePaymentBack/prepareReceiptBackDetail',
        component: () =>
          import(
            '@/views/arap/apbill/preparePaymentBack/prepareReceiptBackDetail'
          ),
        name: 'paymentPrepareReceiptBackDetail',
        meta: {
          title: '预付款退款明细',
          activeMenu: '/arap/apbill/preparePaymentBack'
        }
      }
    ]
  },
  //  手动核销明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['arap:CAwriteOff:handWriteOff'],
    children: [
      {
        path: '/arap/CAwriteOff/handWriteOff/handWriteOffDetail',
        component: () =>
          import('@/views/arap/CAwriteOff/handWriteOff/handWriteOffDetail'),
        name: 'handWriteOffDetail',
        meta: {
          title: '手动核销明细',
          activeMenu: '/arap/apbill/handWriteOff'
        }
      }
    ]
  },
  //产品退料单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:backMaterial'],
    children: [
      {
        path: '/produce/bill/backMaterial/backMaterialDetail',
        component: () =>
          import('@/views/produce/bill/backMaterial/backMaterialDetail'),
        name: 'backMaterialDetail',
        meta: {
          title: '生产退料单明细',
          activeMenu: '/produce/bill/backMaterial'
        }
      }
    ]
  },
  //生产退料单引入源单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:backMaterial'],
    children: [
      {
        path: '/produce/bill/backMaterial/backMaterialImport',
        component: () =>
          import('@/views/produce/bill/backMaterial/backMaterialImport'),
        name: 'backMaterialImport',
        meta: {
          title: '生产退料引入源单',
          activeMenu: '/produce/bill/backMaterial'
        }
      }
    ]
  },

  //产品分货单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:storeDistribute'],
    children: [
      {
        path: '/produce/bill/storeDistribute/storeDistributeDetail',
        component: () =>
          import('@/views/produce/bill/storeDistribute/storeDistributeDetail'),
        name: 'storeDistributeDetail',
        meta: {
          title: '产品分货单明细',
          activeMenu: '/produce/bill/storeDistribute'
        }
      }
    ]
  },
  //产品分货单引入源单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:storeDistribute'],
    children: [
      {
        path: '/produce/bill/storeDistribute/distributeImport',
        component: () =>
          import('@/views/produce/bill/storeDistribute/distributeImport'),
        name: 'distributeImport',
        meta: {
          title: '产品分货引入源单',
          activeMenu: '/produce/bill/storeDistribute'
        }
      }
    ]
  },
  //生产其他订货单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:other:storeOrder'],
    children: [
      {
        path: '/produce/other/storeOrder/storeOrderDetail',
        component: () =>
          import('@/views/produce/other/storeOrder/storeOrderDetail'),
        name: 'storeOrderDetail',
        meta: {
          title: '生产其他订货单',
          activeMenu: '/produce/other/storeOrder'
        }
      }
    ]
  },
  //生产其他原料订货单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:other:storeMaterialOrder'],
    children: [
      {
        path: '/produce/other/storeMaterialOrder/storeMaterialOrderDetail',
        component: () =>
          import(
            '@/views/produce/other/storeMaterialOrder/storeMaterialOrderDetail'
          ),
        name: 'storeMaterialOrderDetail',
        meta: {
          title: '生产其他原料订货单',
          activeMenu: '/produce/other/storeMaterialOrder'
        }
      }
    ]
  },
  //产品入库单新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:inStore'],
    children: [
      {
        path: '/produce/bill/inStore/inStoreDetail',
        component: () => import('@/views/produce/bill/inStore/inStoreDetail'),
        name: 'inStoreDetail',
        meta: {
          title: '产品入库单明细',
          activeMenu: '/produce/bill/inStore'
        }
      }
    ]
  },
  //产品入库单引入源单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:inStore'],
    children: [
      {
        path: '/produce/bill/inStore/inStoreImport',
        component: () => import('@/views/produce/bill/inStore/inStoreImport'),
        name: 'inStoreImport',
        meta: {
          title: '产品入库引入源单',
          activeMenu: '/produce/bill/inStore'
        }
      }
    ]
  },
  //生产领料
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:leadMaterial'],
    children: [
      {
        path: '/produce/bill/leadMaterial/leadMaterialDetail',
        component: () =>
          import('@/views/produce/bill/leadMaterial/leadMaterialDetail'),
        name: 'leadMaterialDetail',
        meta: {
          title: '生产领料单明细',
          activeMenu: '/produce/bill/leadMaterial'
        }
      }
    ]
  },
  //生产退料单引入源单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:leadMaterial'],
    children: [
      {
        path: '/produce/bill/leadMaterial/leadMaterialImport',
        component: () =>
          import('@/views/produce/bill/leadMaterial/leadMaterialImport'),
        name: 'leadMaterialImport',
        meta: {
          title: '生产领料引入源单',
          activeMenu: '/produce/bill/leadMaterial'
        }
      }
    ]
  },
  //生产计划单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:plan'],
    children: [
      {
        path: '/produce/bill/plan/planAdd',
        component: () =>
          import('@/views/produce/bill/plan/planDetail/indexAdd'),
        name: 'PlanAdd',
        meta: {
          title: '生产计划单-新增',
          activeMenu: '/produce/bill/plan'
        }
      }
    ]
  },
  //生产计划单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:plan'],
    children: [
      {
        path: '/produce/bill/plan/planDetail',
        component: () =>
          import('@/views/produce/bill/plan/planDetail/indexDetail'),
        name: 'PlanDetail',
        meta: {
          title: '生产计划单-编辑',
          activeMenu: '/produce/bill/plan'
        }
      }
    ]
  },
  //生产计划单向导生成
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:plan'],
    children: [
      {
        path: '/produce/bill/plan/guideDetail',
        component: () => import('@/views/produce/bill/plan/guideDetail'),
        name: 'guideDetail',
        meta: {
          title: '向导生成明细',
          activeMenu: '/produce/bill/plan'
        }
      }
    ]
  },
  //生产计划单引入源单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:plan'],
    children: [
      {
        path: '/produce/bill/plan/planImport',
        component: () => import('@/views/produce/bill/plan/planImport'),
        name: 'planImport',
        meta: {
          title: '生产计划引入源单',
          activeMenu: '/produce/bill/plan'
        }
      }
    ]
  },
  //智能分货
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:autoDistribute'],
    children: [
      {
        path: '/produce/bill/autoDistribute/autoDistributeDetail',
        component: () =>
          import('@/views/produce/bill/autoDistribute/autoDistributeDetail'),
        name: 'AutoDistributeDetail',
        meta: {
          title: '智能分货明细',
          activeMenu: '/produce/bill/autoDistribute'
        }
      }
    ]
  },
  //生产配料
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bill:matchMaterial'],
    children: [
      {
        path: '/produce/bill/matchMaterial/matchMaterialDetail',
        component: () =>
          import('@/views/produce/bill/matchMaterial/matchMaterialDetail'),
        name: 'MatchMaterialDetail',
        meta: {
          title: '生产配料明细',
          activeMenu: '/produce/bill/matchMaterial'
        }
      }
    ]
  },
  //配方批量修改新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bom:batchedit'],
    children: [
      {
        path: '/produce/bom/batchedit/batcheditDetail',
        component: () =>
          import('@/views/produce/bom/batchedit/batcheditDetail'),
        name: 'batcheditDetail',
        meta: {
          title: '配方批量修改明细',
          activeMenu: '/produce/bom/batchedit'
        }
      }
    ]
  },
  //生产配方新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['produce:bom:service'],
    children: [
      {
        path: '/produce/bom/service/serviceDetail',
        component: () => import('@/views/produce/bom/service/serviceDetail'),
        name: 'serviceDetail',
        meta: {
          title: '配方维护明细',
          activeMenu: '/produce/bom/service'
        }
      }
    ]
  },
  //采购订单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['purchase:bill:order'],
    children: [
      {
        path: '/purchase/order/OrderDetail',
        component: () =>
          import('@/views/purchase/bill/order/OrderDetail/index'),
        name: 'PurchaseOrderDetail',
        meta: {
          title: '采购订单明细',
          activeMenu: '/purchase/bill/PurchaseOrder'
        }
      }
    ]
  },
  //采购入库单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['purchase:bill:instore'],
    children: [
      {
        path: '/purchase/instore/InstoreDetail',
        component: () => import('@/views/purchase/bill/instore/InstoreDetail'),
        name: 'PurchaseInstoreDetail',
        meta: { title: '采购入库明细', activeMenu: '/purchase/bill/instore' }
      }
    ]
  },
  //采购退货单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['purchase:bill:back'],
    children: [
      {
        path: '/purchase/back/BackDetail',
        component: () => import('@/views/purchase/bill/back/BackDetail'),
        name: 'PurchaseBackDetail',
        meta: { title: '采购退货明细', activeMenu: '/purchase/bill/back' }
      }
    ]
  },
  //销售订单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['sale:bill:order'],
    children: [
      {
        path: '/order/addOrder',
        component: () => import('@/views/sale/bill/order/orderDetail'),
        name: 'SaleOrderDetail',
        meta: { title: '销售订单明细', activeMenu: '/sale/bill/order' }
      }
    ]
  },
  //销售出货明细单
  {
    path: '/sale/bill/back',
    component: Layout,
    hidden: true,
    permissions: ['sale:bill:outstore'],
    children: [
      {
        path: 'saleOut-detail',
        component: () => import('@/views/sale/bill/outstore/outstoreDetail'),
        name: 'SaleOutDetail',
        meta: { title: '销售出库单明细', activeMenu: '/sale/bill/outstore' }
      }
    ]
  },
  //销售退货明细单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['sale:bill:back'],
    children: [
      {
        path: '/back/addBack',
        component: () => import('@/views/sale/bill/back/backDetail'),
        name: 'SaleBackDetail',
        meta: { title: '销售退货单明细', activeMenu: '/sale/bill/back' }
      }
    ]
  },
  //门店订货-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:order'],
    children: [
      {
        path: '/shop/bill/order/OrderDetail',
        component: () => import('@/views/shop/bill/order/OrderDetail'),
        name: 'BillOrderDetail',
        meta: {
          title: '门店订货明细',
          activeMenu: '/shop/bill/order',
          type: 'add'
        }
      }
    ]
  },
  //门店订货-编辑
  // {
  //   path: '/system/user-auth',
  //   component: Layout,
  //   hidden: true,
  //   permissions: ['shop:bill:order'],
  //   children: [
  //     {
  //       path: '/shop/bill/order/orderDetailUpdate',
  //       component: () => import('@/views/shop/bill/order/OrderDetail'),
  //       name: 'BillOrderDetail',
  //       meta: {
  //         title: '门店订货-编辑',
  //         activeMenu: '/shop/bill/order',
  //         type: 'update'
  //       }
  //     }
  //   ]
  // },
  //门店销售汇总明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:report:saleTotal'],
    children: [
      {
        path: 'shop/report/saleTotal/saleTotalDetail',
        component: () =>
          import('@/views/shop/report/saleTotal/saleTotalDetail'),
        name: 'saleTotalDetail',
        meta: {
          title: '门店销售汇总明细',
          activeMenu: '/shop/report/saleTotal'
        }
      }
    ]
  },
  //门店销售订单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['sale:bill:order'],
    children: [
      {
        path: '/clientSaleOrderDetail/detail',
        component: () =>
          import('@/views/shop/bill/clientSaleOrder/clientSaleOrderDetail'),
        name: 'shopClientSaleOrderDetail',
        meta: {
          title: '门店销售订单明细',
          activeMenu: '/shop/bill/clientSaleOrder'
        }
      }
    ]
  },
  //门店预定汇总明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:report:posReserveQuery'],
    children: [
      {
        path: 'shop/report/posReserveQuery/posReserveQueryDetail',
        component: () =>
          import('@/views/shop/report/posReserveQuery/posReserveQueryDetail'),
        name: 'posReserveQueryDetail',
        meta: {
          title: '门店预定汇总明细',
          activeMenu: '/shop/report/posReserveQuery'
        }
      }
    ]
  },
  //门店原料订货明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:materialOrder'],
    children: [
      {
        path: '/shop/bill/materialOrder/materialOrderDetail',
        component: () =>
          import('@/views/shop/bill/materialOrder/materialOrderDetail'),
        name: 'MaterialOrderDetail',
        meta: {
          title: '门店原料订货单明细',
          activeMenu: '/shop/bill/materialOrder'
        }
      }
    ]
  },
  //门店预订单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:reserveOrder'],
    children: [
      {
        path: '/shop/bill/reserveOrder/reserveOrderDetail',
        component: () =>
          import('@/views/shop/bill/reserveOrder/reserveOrderDetail'),
        name: 'ReserveOrderDetail',
        meta: {
          title: '门店后台订单明细',
          activeMenu: '/shop/bill/reserveOrder'
        }
      }
    ]
  },
  //门店采购明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:stockIn'],
    children: [
      {
        path: '/shop/bill/stockIn/StockInDetail',
        component: () => import('@/views/shop/bill/stockIn/StockInDetail'),
        name: 'StockInDetail',
        meta: { title: '门店采购单明细', activeMenu: '/shop/bill/stockIn' }
      }
    ]
  },
  // 门店采购退货明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:stockInBack'],
    children: [
      {
        path: '/shop/bill/stockInBack/detail',
        component: () => import('@/views/shop/bill/stockInBack/detail'),
        name: 'StockInBackDetail',
        meta: { title: '门店采购退货单明细', activeMenu: '/shop/bill/stockInBack' }
      }
    ]
  },
  //门店销售明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:clientSale'],
    children: [
      {
        path: '/shop/bill/clientSale/ClientSaleDetail',
        component: () =>
          import('@/views/shop/bill/clientSale/ClientSaleDetail'),
        name: 'ClientSaleDetail',
        meta: {
          title: '门店销售单明细',
          activeMenu: '/shop/bill/ClientSaleDetail'
        }
      }
    ]
  },
  //门店报废明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:scrap'],
    children: [
      {
        path: '/shop/bill/scrap/ScrapDetail',
        component: () => import('@/views/shop/bill/scrap/ScrapDetail'),
        name: 'ScrapDetail',
        meta: { title: '门店报废单明细', activeMenu: '/shop/bill/scrap' }
      }
    ]
  },
  //门店调入明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:moveIn'],
    children: [
      {
        path: '/shop/bill/moveIn/MoveInDetail',
        component: () => import('@/views/shop/bill/moveIn/MoveInDetail'),
        name: 'MoveInDetail',
        meta: { title: '门店调入单明细', activeMenu: '/shop/bill/moveIn' }
      }
    ]
  },
  //门店调出明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:moveOut'],
    children: [
      {
        path: '/shop/bill/moveOut/MoveOutDetail',
        component: () => import('@/views/shop/bill/moveOut/MoveOutDetail'),
        name: 'MoveOutDetail',
        meta: { title: '门店调出单明细', activeMenu: '/shop/bill/moveOut' }
      }
    ]
  },
  //门店生产明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:produce'],
    children: [
      {
        path: '/shop/bill/produce/ProduceDetail',
        component: () => import('@/views/shop/bill/produce/ProduceDetail'),
        name: 'ProduceDetail',
        meta: { title: '门店生产单明细', activeMenu: '/shop/bill/produce' }
      }
    ]
  },
  //门店领料明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:waste'],
    children: [
      {
        path: '/shop/bill/waste/WasteDetail',
        component: () => import('@/views/shop/bill/waste/WasteDetail'),
        name: 'WasteDetail',
        meta: { title: '门店原料消耗单明细', activeMenu: '/shop/bill/waste' }
      }
    ]
  },
  //门店盘点明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:check'],
    children: [
      {
        path: '/shop/bill/check/CheckDetail',
        component: () => import('@/views/shop/bill/check/CheckDetail'),
        name: 'checkDetail',
        meta: { title: '门店盘点单明细', activeMenu: '/shop/bill/check' }
      }
    ]
  },
  //门店盘盈单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:checkFull'],
    children: [
      {
        path: '/shop/bill/checkFull/CheckFullDetail',
        component: () => import('@/views/shop/bill/checkFull/CheckFullDetail'),
        name: 'checkFullDetail',
        meta: { title: '门店盘盈单明细', activeMenu: '/shop/bill/checkFull' }
      }
    ]
  },
  //门店盘亏单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['shop:bill:checkLose'],
    children: [
      {
        path: '/shop/bill/checkLose/CheckLoseDetail',
        component: () => import('@/views/shop/bill/checkLose/CheckLoseDetail'),
        name: 'checkLoseDetail',
        meta: { title: '门店盘亏单明细', activeMenu: '/shop/bill/checkLose' }
      }
    ]
  },
  //促销方案
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/components/SpecialScheme',
        component: () =>
          import(
            '@/views/marketing/promo/specialprice/components/SpecialScheme'
          ),
        name: 'SpecialScheme',
        meta: {
          title: '选择促销类型',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-特价促销
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/specialPromo',
        component: () =>
          import('@/views/marketing/promo/specialprice/specialPromo'),
        name: 'SpecialPromo',
        meta: {
          title: '促销方案-特价促销',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-数量特价
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/quantitySpecial',
        component: () =>
          import('@/views/marketing/promo/specialprice/quantitySpecial'),
        name: 'QuantitySpecial',
        meta: {
          title: '促销方案-数量特价',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-固定组合
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/fixedGroup',
        component: () =>
          import('@/views/marketing/promo/specialprice/fixedGroup'),
        name: 'FixedGroup',
        meta: {
          title: '促销方案-固定组合',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-动态组合
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/dynamicGroup',
        component: () =>
          import('@/views/marketing/promo/specialprice/dynamicGroup'),
        name: 'DynamicGroup',
        meta: {
          title: '促销方案-动态组合',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-折扣优惠
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/discount',
        component: () =>
          import('@/views/marketing/promo/specialprice/discount'),
        name: 'Discount',
        meta: {
          title: '促销方案-折扣优惠',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-数量折扣
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/amountDiscount',
        component: () =>
          import('@/views/marketing/promo/specialprice/amountDiscount'),
        name: 'AmountDiscount',
        meta: {
          title: '促销方案-数量折扣',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-金额折扣
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/moneyDiscount',
        component: () =>
          import('@/views/marketing/promo/specialprice/moneyDiscount'),
        name: 'MoneyDiscount',
        meta: {
          title: '促销方案-金额折扣',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-金额满减
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/moneyFullDiscount',
        component: () =>
          import('@/views/marketing/promo/specialprice/moneyFullDiscount'),
        name: 'MoneyFullDiscount',
        meta: {
          title: '促销方案-金额满减',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-预订优惠
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/resetDiscounts',
        component: () =>
          import('@/views/marketing/promo/specialprice/resetDiscounts'),
        name: 'ResetDiscounts',
        meta: {
          title: '促销方案-预订优惠',
          activeMenu: '/marketing/promo/resetDiscounts'
        }
      }
    ]
  },
  //促销方案-买满赠送
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/fulnessFullGift',
        component: () =>
          import('@/views/marketing/promo/specialprice/fulnessFullGift'),
        name: 'FulnessFullGift',
        meta: {
          title: '促销方案-买满赠送',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-充值免单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/refillFree',
        component: () =>
          import('@/views/marketing/promo/specialprice/refillFree'),
        name: 'RefillFree',
        meta: {
          title: '促销方案-充值免单',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-组合优惠
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/groupDiscounts',
        component: () =>
          import('@/views/marketing/promo/specialprice/groupDiscounts'),
        name: 'GroupDiscounts',
        meta: {
          title: '促销方案-组合优惠',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //促销方案-额外赠送
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:promo:specialprice'],
    children: [
      {
        path: '/marketing/promo/specialprice/bonusGift',
        component: () =>
          import('@/views/marketing/promo/specialprice/bonusGift'),
        name: 'BonusGift',
        meta: {
          title: '促销方案-额外赠送',
          activeMenu: '/marketing/promo/specialprice'
        }
      }
    ]
  },
  //优惠券方案
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:case'],
    children: [
      {
        path: '/marketing/coupon/case/components/discountScheme',
        component: () =>
          import('@/views/marketing/coupon/case/components/discountScheme'),
        name: 'DiscountScheme',
        meta: {
          title: '选择优惠券类型',
          activeMenu: '/marketing/coupon/case'
        }
      }
    ]
  },
  //优惠券-代金券
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:case'],
    children: [
      {
        path: 'Voucher',
        component: () =>
          import('@/views/marketing/coupon/case/voucher/index.vue'),
        name: 'Voucher',
        meta: {
          title: '优惠券方案-代金券',
          activeMenu: '/marketing/coupon/case'
        }
      }
    ]
  },
  //优惠券-折扣券
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:case'],
    children: [
      {
        path: 'DiscountCoupon',
        component: () =>
          import('@/views/marketing/coupon/case/discountCoupon/index.vue'),
        name: 'DiscountCoupon',
        meta: {
          title: '优惠券方案-折扣券',
          activeMenu: '/marketing/coupon/case'
        }
      }
    ]
  },
  //优惠券-抵扣券
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:case'],
    children: [
      {
        path: 'DeductionCoupon',
        component: () =>
          import('@/views/marketing/coupon/case/deductionCoupon/index.vue'),
        name: 'DeductionCoupon',
        meta: {
          title: '优惠券方案-抵扣券',
          activeMenu: '/marketing/coupon/case'
        }
      }
    ]
  },
    //优惠券-充值券
    {
      path: '/system/user-auth',
      component: Layout,
      hidden: true,
      permissions: ['marketing:coupon:case'],
      children: [
        {
          path: 'RechargeCoupon',
          component: () =>
            import('@/views/marketing/coupon/case/rechargeCoupon/index.vue'),
          name: 'RechargeCoupon',
          meta: {
            title: '优惠券方案-充值券',
            activeMenu: '/marketing/coupon/case'
          }
        }
      ]
    },
  //优惠券-生成纸质优惠券
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:case'],
    children: [
      {
        path: 'GenerateCouppon',
        component: () =>
          import('@/views/marketing/coupon/case/generateCouppon/index.vue'),
        name: 'GenerateCouppon',
        meta: {
          title: '优惠券方案-生成优惠券',
          activeMenu: '/marketing/coupon/case'
        }
      }
    ]
  },
      //优惠券-生成纸质优惠券一优惠券明细
      {
        path: '/system/user-auth',
        component: Layout,
        hidden: true,
        permissions: ['marketing:coupon:case'],
        children: [
          {
            path: 'GenerateCoupponDetail',
            component: () =>
              import('@/views/marketing/coupon/case/generateCouppon/couponDetail.vue'),
            name: 'GenerateCoupponDetail',
            meta: {
              title: '优惠券方案-生成优惠券-优惠券明细',
              activeMenu: '/marketing/coupon/case'
            }
          }
        ]
      },
  //优惠券领出
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:receive'],
    children: [
      {
        path: 'CouponReceipveDetail',
        component: () =>
          import('@/views/marketing/coupon/receive/component/index.vue'),
        name: 'CouponReceipveDetail',
        meta: {
          title: '优惠券领出明细',
          activeMenu: '/marketing/coupon/receive'
        }
      }
    ]
  },
  //优惠券销售
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['marketing:coupon:sale'],
    children: [
      {
        path: 'CouponSaleDetail',
        component: () =>
          import('@/views/marketing/coupon/sale/component/index.vue'),
        name: 'CouponSaleDetail',
        meta: {
          title: '优惠券销售明细',
          activeMenu: '/marketing/coupon/sale'
        }
      }
    ]
  },
  // 商品组装单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:combinStrip:combinBill'],
    children: [
      {
        path: '/store/combinBill/combinBillDetail',
        component: () =>
          import(
            '@/views/store/combinStrip/combinBill/combinBillDetail/index.vue'
          ),
        name: 'storeCombinBillDetail',
        meta: {
          title: '商品组装单明细',
          activeMenu: '/store/combinStrip/combinBill'
        }
      }
    ]
  },
  // 商品拆卸单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:combinStrip:stripBill'],
    children: [
      {
        path: '/store/stripBill/stripBillDetail',
        component: () =>
          import(
            '@/views/store/combinStrip/stripBill/stripBillDetail/index.vue'
          ),
        name: 'storeStripBillDetail',
        meta: {
          title: '商品拆卸单明细',
          activeMenu: '/store/combinStrip/stripBill'
        }
      }
    ]
  },
  //仓库盘点明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:check'],
    children: [
      {
        path: '/store/check/checkDetail',
        component: () =>
          import('@/views/store/bill/check/checkDetail/index.vue'),
        name: 'storeCheckDetail',
        meta: { title: '仓库盘点单明细', activeMenu: '/store/bill/check' }
      }
    ]
  },
  //库存调整单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:adjust'],
    children: [
      {
        path: '/store/stock/adjustDetail',
        component: () => import('@/views/store/bill/adjust/adjustDetail'),
        name: 'storeAdjustDetail',
        meta: { title: '库存调整单明细', activeMenu: '/store/bill/adjust' }
      }
    ]
  },
  //新增仓库报损
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:waste'],
    children: [
      {
        path: '/waste/wasteDetail',
        component: () => import('@/views/store/bill/waste/wasteDetail'),
        name: 'storeWasteDetail',
        meta: { title: '仓库报损单明细', activeMenu: '/store/bill/waste' }
      }
    ]
  },
  //仓库调拨单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:move'],
    children: [
      {
        path: '/move/moveDetail',
        component: () => import('@/views/store/bill/move/moveDetail'),
        name: 'storeMoveDetail',
        meta: { title: '仓库调拨单明细', activeMenu: '/store/bill/move' }
      }
    ]
  },
  //仓库调出明细单
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:moveOut'],
    children: [
      {
        path: '/moveOut/moveOutDetail',
        component: () => import('@/views/store/bill/moveOut/moveOutDetail'),
        name: 'storeMoveOutDetail',
        meta: { title: '仓库调出单明细', activeMenu: '/store/bill/moveOut' }
      }
    ]
  },
  //仓库调入单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:moveIn'],
    children: [
      {
        path: '/moveIn/moveInDetail',
        component: () => import('@/views/store/bill/moveIn/moveInDetail'),
        name: 'storeMoveInDetail',
        meta: { title: '仓库调入单明细', activeMenu: '/store/bill/moveIn' }
      }
    ]
  },

  //调拨差异单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:moveDiff'],
    children: [
      {
        path: '/moveDiff/moveDiffDetail',
        component: () => import('@/views/store/bill/moveDiff/moveDiffDetail'),
        name: 'storeMoveDiffDetail',
        meta: { title: '调拨差异单明细', activeMenu: '/store/bill/moveDiff' }
      }
    ]
  },
  //仓库盘盈单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:checkFull'],
    children: [
      {
        path: '/checkFull/checkFullDetail',
        component: () => import('@/views/store/bill/checkFull/checkFullDetail'),
        name: 'storeCheckFullDetail',
        meta: { title: '仓库盘盈单明细', activeMenu: '/store/bill/checkFull' }
      }
    ]
  },
  //仓库盘亏单明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:checkLose'],
    children: [
      {
        path: '/checkLose/checkLose',
        component: () => import('@/views/store/bill/checkLose/checkLoseDetail'),
        name: 'storeCheckLoseDetail',
        meta: { title: '仓库盘亏单明细', activeMenu: '/store/bill/checkLose' }
      }
    ]
  },
  // 成本调整
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['store:bill:costPriceAdjust'],
    children: [
      {
        path: '/costPriceAdjust/detail',
        component: () => import('@/views/store/bill/costPriceAdjust/detail'),
        name: 'CostPriceAdjustDetail',
        meta: { title: '成本调整单明细', activeMenu: '/store/bill/costPriceAdjust' }
      }
    ]
  },
  //客户新增基本信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:partner:customer'],
    children: [
      {
        path: '/partner/customer/newCustomers',
        component: () => import('@/views/system/partner/customer/newCustomers'),
        name: 'newCustomers',
        meta: { title: '客户资料明细', activeMenu: '/goods/customer' }
      }
    ]
  },
  //供商新增基本信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:partner:supplier'],
    children: [
      {
        path: '/partner/supplier/newSupplier',
        component: () => import('@/views/system/partner/supplier/newSupplier'),
        name: 'newSupplier',
        meta: { title: '供商资料明细', activeMenu: '/goods/supplier' }
      }
    ]
  },
  //会员充值规则新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:fillRule'],
    children: [
      {
        path: '/vip/base/fillRule/fillRuleDetail',
        component: () => import('@/views/vip/base/fillRule/fillRuleDetail'),
        name: 'fillRuleDetail',
        meta: {
          title: '会员充值规则明细',
          activeMenu: '/vipcard/base/fillRule'
        }
      }
    ]
  },
  //会员折扣规则新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:discountRule'],
    children: [
      {
        path: '/vip/base/discountRule/discountRuleDetail',
        component: () =>
          import('@/views/vip/base/discountRule/discountRuleDetail'),
        name: 'discountRuleDetail',
        meta: {
          title: '会员折扣规则明细',
          activeMenu: '/vipcard/base/discountRule'
        }
      }
    ]
  },

  //会员积分规则新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:scoreRule'],
    children: [
      {
        path: '/vip/base/scoreRule/scoreRuleDetail',
        component: () => import('@/views/vip/base/scoreRule/scoreRuleDetail'),
        name: 'scoreRuleDetail',
        meta: {
          title: '会员积分规则明细',
          activeMenu: '/vipcard/base/scoreRule'
        }
      }
    ]
  },
  //会员积分抵现规则新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['vip:base:rebateRule'],
    children: [
      {
        path: '/vip/base/rebateRule/rebateRuleDetail',
        component: () => import('@/views/vip/base/rebateRule/rebateRuleDetail'),
        name: 'rebateRuleDetail',
        meta: {
          title: '会员积分抵现规则明细',
          activeMenu: '/vipcard/base/rebateRuleRule'
        }
      }
    ]
  },

  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:employee:list:edit'],
    children: [
      {
        path: 'role/:employeeId(\\d+)',
        component: () => import('@/views/system/right/employee/authRole'),
        name: 'AuthRole',
        meta: { title: '分配角色', activeMenu: '/system/employee' }
      }
    ]
  },
  //会员业务
  //会员充值
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:fill'],
    children: [
      {
        path: '/vip/bill/fill/fillDetail',
        component: () => import('@/views/vip/bill/fill/fillDetail'),
        name: 'FillDetail',
        meta: {
          title: '会员充值单据',
          activeMenu: '/vip/bill/fill'
        }
      }
    ]
  },
  //会员换卡
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:cardChange'],
    children: [
      {
        path: '/vip/bill/cardChange/cardChangeDetail',
        component: () => import('@/views/vip/bill/cardChange/cardChangeDetail'),
        name: 'CardChangeDetail',
        meta: {
          title: '会员换卡方案',
          activeMenu: '/vip/bill/cardChange'
        }
      }
    ]
  },
  //会员积分调整
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:score'],
    children: [
      {
        path: '/vip/bill/score/scoreDetail',
        component: () => import('@/views/vip/bill/score/scoreDetail'),
        name: 'ScoreDetail',
        meta: {
          title: '会员积分调整单据',
          activeMenu: '/vip/bill/score'
        }
      }
    ]
  },
  //会员状态调整
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:stateChange'],
    children: [
      {
        path: '/vip/bill/stateChange/stateChangeDetail',
        component: () =>
          import('@/views/vip/bill/stateChange/stateChangeDetail'),
        name: 'StateChangeDetail',
        meta: {
          title: '会员状态调整单据',
          activeMenu: '/vip/bill/stateChange'
        }
      }
    ]
  },
  //会员更换级别
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:levelChange'],
    children: [
      {
        path: '/vip/bill/levelChange/levelChangeDetail',
        component: () =>
          import('@/views/vip/bill/levelChange/levelChangeDetail'),
        name: 'LevelChangeDetail',
        meta: {
          title: '会员更换级别单据',
          activeMenu: '/vip/bill/levelChange'
        }
      }
    ]
  },
  //会员积分兑换
  {
    path: '/',
    component: Layout,
    hidden: true,
    permissions: ['vip:bill:scoreExchange'],
    children: [
      {
        path: '/vip/bill/scoreExchange/scoreExchangeDetail',
        component: () =>
          import('@/views/vip/bill/scoreExchange/scoreExchangeDetail'),
        name: 'ScoreExchangeDetail',
        meta: {
          title: '会员积分兑换单据',
          activeMenu: '/vip/bill/scoreExchange'
        }
      }
    ]
  },
  {
    path: '/system/role-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:employee:authRole'],
    children: [
      {
        path: 'user/:roleId(\\d+)',
        component: () => import('@/views/system/right/role/authUser'),
        name: 'AuthUser',
        meta: { title: '分配员工角色', activeMenu: '/system/role' }
      }
    ]
  },

  // 系统数据字
  {
    path: '/system/dict-data/system',
    component: Layout,
    hidden: true,
    permissions: ['system:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/tenant/config/dict/data'),
        name: 'systemDictionary',
        meta: { title: '系统字典数据', activeMenu: '/system/dict' }
      }
    ]
  },

  // 业务数据字典
  {
    path: '/system/dict-data/business',
    component: Layout,
    hidden: true,
    permissions: ['business:dict:list'],
    children: [
      {
        path: 'index/:dictId(\\d+)',
        component: () => import('@/views/system/maintain/businessDict/data'),
        name: 'businessDictionary',
        meta: { title: '业务字典数据', activeMenu: '/system/dict' }
      }
    ]
  },
  //线上商城-商品管理-商城商品编辑
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:goods:list'],
    children: [
      {
        path: '/O2OMall/goods/list/editGoods/index',
        component: () => import('@/views/O2OMall/goods/list/editGood/index'),
        name: 'O2OMallEditGoods',
        meta: {
          title: '商城商品信息编辑',
          activeMenu: '/O2OMall/goods/list/editGoods'
        }
      }
    ]
  },
  //点餐小程序-商品管理-商品编辑
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:orderMealWxa:goods'],
    children: [
      {
        path: '/O2OThird/orderMealWxa/goods/editGoods/index',
        component: () => import('@/views/O2OMall/goods/list/editGood/index'),
        name: 'O2OThirdEditGoods',
        meta: {
          title: '点餐商品信息编辑',
          activeMenu: '/O2OMall/goods/list/editGoods'
        }
      }
    ]
  },
  //线上商城-订单管理-商城订单列表
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:order:list'],
    children: [
      {
        path: '/O2OMall/order/list/orderManage/index',
        component: () => import('@/views/O2OMall/order/list/orderManage/index'),
        name: 'OrderManage',
        meta: {
          title: '商城订单详情',
          activeMenu: '/O2OMall/rder/list/orderManage'
        }
      }
    ]
  },
  //线上商城-配送管理-蜂鸟配送
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:manage:delivery'],
    children: [
      {
        path: '/O2OMall/manage/delivery/components/fengNiao',
        component: () => import('@/views/O2OMall/manage/delivery/components/fengNiao'),
        name: 'ManageDelivery',
        meta: {
          title: '蜂鸟配送',
          activeMenu: '/O2OMall/manage/delivery/index'
        }
      }
    ]
  },
  {
    path: '/monitor/job-log',
    component: Layout,
    hidden: true,
    permissions: ['monitor:job:list'],
    children: [
      {
        path: 'index',
        component: () => import('@/views/system/monitor/job/log'),
        name: 'JobLog',
        meta: { title: '调度日志', activeMenu: '/monitor/job' }
      }
    ]
  },
  {
    path: '/tool/gen-edit',
    component: Layout,
    hidden: true,
    permissions: ['tool:gen:edit'],
    children: [
      {
        path: 'index/:tableId(\\d+)',
        component: () => import('@/views/system/tool/gen/editTable'),
        name: 'GenEdit',
        meta: { title: '修改生成配置', activeMenu: '/tool/gen' }
      }
    ]
  },
  //线上商城-商城管理-运费管理-新增运费模板
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:manage:freight'],
    children: [
      {
        path: '/O2OMall/manage/freight/addFreightTemp/index',
        component: () =>
          import('@/views/O2OMall/manage/freight/addFreightTemp/index.vue'),
        name: 'AddFreightTemp',
        meta: {
          title: '运费方案',
          activeMenu: '/O2OMall/marketing/freight'
        }
      }
    ]
  },
  //线上商城-商城管理-商城门店-编辑门店信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:manage:shop'],
    children: [
      {
        path: '/O2OMall/manage/shopEdit/index',
        component: () =>
          import('@/views/O2OMall/manage/shop/shopEdit/index.vue'),
        name: 'shopEdit',
        meta: {
          title: '商城门店管理',
          activeMenu: '/O2OMall/marketing/shopEdit'
        }
      }
    ]
  },
  //点餐程序-下单门店-编辑门店信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:orderMealWxa:shop'],
    children: [
      {
        path: '/O2OThird/orderMealWxa/shop/shopEdit',
        component: () =>
          import('@/views/O2OMall/manage/shop/shopEdit/index.vue'),
        name: 'shopEdit',
        meta: {
          title: '点餐门店管理',
          activeMenu: '/O2OMall/marketing/shopEdit'
        }
      }
    ]
  },
  //线上商城-商城营销-拼团明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:fightGroup'],
    children: [
      {
        path: '/O2OMall/marketing/fightGroup/fightGroupDetail',
        component: () =>
          import('@/views/O2OMall/marketing/fightGroup/fightGroupDetail'),
        name: 'FightGroupDetail',
        meta: {
          title: '商城营销-拼团',
          activeMenu: '/O2OMall/marketing/fightGroup'
        }
      }
    ]
  },
  //线上商城-商城营销-秒杀明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:seckill'],
    children: [
      {
        path: '/O2OMall/marketing/seckill/seckillDetail',
        component: () =>
          import('@/views/O2OMall/marketing/seckill/seckillDetail'),
        name: 'SeckillDetail',
        meta: {
          title: '商城营销-秒杀',
          activeMenu: '/O2OMall/marketing/seckill'
        }
      }
    ]
  },
  //线上商城-商城营销-砍价明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:bargain'],
    children: [
      {
        path: '/O2OMall/marketing/bargain/bargainDetail',
        component: () =>
          import('@/views/O2OMall/marketing/bargain/bargainDetail'),
        name: 'BargainDetail',
        meta: {
          title: '商城营销-砍价',
          activeMenu: '/O2OMall/marketing/bargain'
        }
      }
    ]
  },
  //线上商城-商城营销-抽奖活动
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:luckDraw'],
    children: [
      {
        path: '/O2OMall/marketing/luckDraw/luckDrawDetail',
        component: () =>
          import('@/views/O2OMall/marketing/luckDraw/luckDrawDetail'),
        name: 'LuckDrawDetail',
        meta: {
          title: '商城营销-抽奖活动',
          activeMenu: '/O2OMall/marketing/luckDraw'
        }
      }
    ]
  },
  //线上商城-商城营销-广告明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:popupAdCase'],
    children: [
      {
        path: '/O2OMall/marketing/popupAdCase/popupAdCaseDetail',
        component: () =>
          import('@/views/O2OMall/marketing/popupAdCase/popupAdCaseDetail'),
        name: 'popupAdCaseDetail',
        meta: {
          title: '商城营销-弹窗广告明细',
          activeMenu: '/O2OMall/marketing/popupAdCase'
        }
      }
    ]
  },
  //线上商城-商城营销-社群码
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:wxCommunityCode'],
    children: [
      {
        path: '/O2OMall/marketing/wxCommunityCode/wxCommunityCodeDetail',
        component: () =>
          import('@/views/O2OMall/marketing/wxCommunityCode/wxCommunityCodeDetail'),
        name: 'wxCommunityCodeDetail',
        meta: {
          title: '商城营销-社群码管理',
          activeMenu: '/O2OMall/marketing/wxCommunityCode'
        }
      }
    ]
  },
  //蛋糕自选-商品管理-商品编辑
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:cakeFreeSelectApp:goods'],
    children: [
      {
        path: '/O2OThird/cakeFreeSelectApp/goods/editGoods/index',
        component: () => import('@/views/O2OMall/goods/list/editGood/index'),
        name: 'O2OThirdEditGoods',
        meta: {
          title: '蛋糕自选商品信息编辑',
          activeMenu: '/O2OMall/goods/list/editGoods'
        }
      }
    ]
  },
  //蛋糕自选门店-编辑门店信息
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OThird:cakeFreeSelectApp:shop'],
    children: [
      {
        path: '/O2OThird/cakeFreeSelectApp/shopEdit',
        component: () =>
          import('@/views/O2OMall/manage/shop/shopEdit/index.vue'),
        name: 'shopEdit',
        meta: {
          title: '蛋糕自选门店管理',
          activeMenu: '/O2OThird/cakeFreeSelectApp/shopEdit'
        }
      }
    ]
  },
  //线上商城-商城营销-邀请有礼
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:inviteGift'],
    children: [
      {
        path: '/O2OMall/marketing/inviteGift/inviteGiftDetail',
        component: () =>
          import('@/views/O2OMall/marketing/inviteGift/inviteGiftDetail'),
        name: 'InviteGiftDetail',
        meta: {
          title: '商城营销-邀请有礼',
          activeMenu: '/O2OMall/marketing/inviteGift'
        }
      }
    ]
  },
  //线上商城-商城营销-助力瓜分优惠券
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:carveUpCoupon'],
    children: [
      {
        path: '/O2OMall/marketing/carveUpCoupon/carveUpCouponDetail',
        component: () =>
          import('@/views/O2OMall/marketing/carveUpCoupon/carveUpCouponDetail'),
        name: 'CarveUpCouponDetail',
        meta: {
          title: '商城营销-助力瓜分优惠券',
          activeMenu: '/O2OMall/marketing/carveUpCoupon'
        }
      }
    ]
  },
  //线上商城-商城营销-优惠券裂变
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:couponFission'],
    children: [
      {
        path: '/O2OMall/marketing/couponFission/couponFissionDetail',
        component: () =>
          import('@/views/O2OMall/marketing/couponFission/couponFissionDetail'),
        name: 'CouponFissionDetail',
        meta: {
          title: '商城营销-优惠券裂变',
          activeMenu: '/O2OMall/marketing/couponFission'
        }
      }
    ]
  },
  //线上商城-订单管理-商城订单列表
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:order:list'],
    children: [
      {
        path: '/O2OMall/order/list/orderManage/index',
        component: () => import('@/views/O2OMall/order/list/orderManage/index'),
        name: 'OrderManage',
        meta: {
          title: '商城订单详情',
          activeMenu: '/O2OMall/rder/list/orderManage'
        }
      }
    ]
  },
  //线上商城-商城营销-领券明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['O2OMall:marketing:drawCouponCenter'],
    children: [
      {
        path: '/O2OMall/marketing/drawCouponCenterDetail',
        component: () =>
          import(
            '@/views/O2OMall/marketing/drawCouponCenter/drawCouponCenterDetail'
          ),
        name: 'DrawCouponCenterDetail',
        meta: {
          title: '领券明细',
          activeMenu: 'O2OMall/marketing/drawCouponCenter'
        }
      }
    ]
  },
  //商户管理-商户资料-商户信息明细页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:base:info'],
    children: [
      {
        path: '/tenant/base/info/handleTenantInfoDetail',
        component: () =>
          import('@/views/tenant/base/info/components/handleTenantInfoDetail'),
        name: 'HandleTenantInfoDetail',
        meta: { title: '商户信息明细', activeMenu: '/sale/bill/order' }
      }
    ]
  },
  //商户管理-商户资料-商户列表续费页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:base:info'],
    children: [
      {
        path: '/tenant/base/info/renew',
        component: () =>
          import('@/views/tenant/base/info/components/renew.vue'),
        name: 'baseInfoRenew',
        // 根据类型，动态设置标题
        meta: {
          title: '商户续费',
          activeMenu: '/info/components/renew'
        }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:base:info'],
    children: [
      {
        path: '/tenant/base/info/handleProduct',
        component: () =>
          import('@/views/tenant/base/info/components/handleProduct.vue'),
        name: 'baseInfoRenewSelect',
        meta: {
          title: '选择产品',
          type: true,
          activeMenu: '/info/components/renew'
        }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:base:info'],
    children: [
      {
        path: '/tenant/base/info/upgradation',
        component: () =>
          import('@/views/tenant/base/info/components/upgradation.vue'),
        name: 'baseInfoUpgradation',
        // 根据类型，动态设置标题
        meta: {
          title: '商户升级',
          type: true,
          activeMenu: '/info/components/upgradation'
        }
      }
    ]
  },
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:base:info:purchase'],
    children: [
      {
        path: '/tenant/base/info/purchase',
        component: () =>
          import('@/views/tenant/base/info/components/purchase.vue'),
        name: 'purchase',
        meta: { title: '商户购买', activeMenu: '/nfo/components/purchase' }
      }
    ]
  },
  // 商户管理-商户产品-商户产品查看明细
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['system:product:query'],
    children: [
      {
        path: '/tenant/product/list/components/handleProductDetail',
        component: () =>
          import('@/views/tenant/product/list/components/handleProductDetail'),
        name: 'HandleProductDetail',
        meta: { title: '商户产品查看明细', activeMenu: '/sale/bill/order' }
      }
    ]
  },
  //商户管理-产品管理-系统产品明细页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:product:saas'],
    children: [
      {
        path: '/tenant/product/saas/productDetail',
        component: () =>
          import('@/views/tenant/product/saas/components/handleProductDetail'),
        name: 'HandleProductDetail',
        meta: { title: '系统产品明细', activeMenu: '/sale/bill/order' }
      }
    ]
  },
  //商户管理-产品管理-功能模块明细页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:product:module'],
    children: [
      {
        path: '/tenant/product/module/moduleDetail',
        component: () =>
          import('@/views/tenant/product/module/components/handleModuleDetail'),
        name: 'HandleProductDetail',
        meta: { title: '功能模块明细', activeMenu: '/tenant/product/module' }
      }
    ]
  },
  //商户管理-产品管理-其他产品明细页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:product:package'],
    children: [
      {
        path: '/tenant/product/package/packageDetail',
        component: () =>
          import(
            '@/views/tenant/product/package/components/handlePackageDetail'
          ),
        name: 'HandlePackageDetail',
        meta: { title: '其他产品明细', activeMenu: '/tenant/product/package' }
      }
    ]
  },
  //商户管理-产品管理-产品订单明细页面
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['tenant:product:order'],
    children: [
      {
        path: '/tenant/product/order/orderDetail',
        component: () =>
          import('@/views/tenant/product/order/components/handleOrderDetail'),
        name: 'HandleOrderDetail',
        meta: { title: '产品订单明细', activeMenu: '/tenant/product/order' }
      }
    ]
  },
  // 授予权限
  {
    path: '/shop/bill',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:cashier'],
    children: [
      {
        path: '/shop/base/cashier/staffJurisdiction',
        component: () =>
          import('@/views/shop/base/cashier/aotu/staffJurisdiction.vue'),
        name: 'StaffJurisdiction',
        meta: {
          title: '授予权限',
          activeMenu: '/shop/base/cashier/staffJurisdiction'
        }
      }
    ]
  },
  // 分组权限
  {
    path: '/shop/bill',
    component: Layout,
    hidden: true,
    permissions: ['shop:base:cashier'],
    children: [
      {
        path: '/shop/base/cashier/groupJurisdiction',
        component: () =>
          import('@/views/shop/base/cashier/aotu/groupJurisdiction.vue'),
        name: 'GroupJurisdiction',
        meta: {
          title: '分组权限',
          activeMenu: '/shop/base/cashier/groupJurisdiction'
        }
      }
    ]
  },
  //裱花间半成品订货-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:order:goodsOrder'],
    children: [
      {
        path: '/decoration/order/goodsOrder/goodsOrderDetail',
        component: () => import('@/views/decoration/order/goodsOrder/goodsOrderDetail'),
        name: 'GoodsOrderDetail',
        meta: {
          title: '裱花间半成品订货明细',
          activeMenu: '/goodsOrder/goodsOrder/goodsOrder',
          type: 'add'
        }
      }
    ]
  },
  //现烤半成品订货-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:order:goodsOrder'],
    children: [
      {
        path: '/freshlyBaked/order/goodsOrder/goodsOrderDetail',
        component: () => import('@/views/freshlyBaked/order/goodsOrder/goodsOrderDetail'),
        name: 'FreshlyBakedGoodsOrderDetail',
        meta: {
          title: '现烤间半成品订货明细',
          activeMenu: '/freshlyBaked/order/goodsOrder',
          type: 'add'
        }
      }
    ]
  },
  //门店现烤订单-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:shopOrder'],
    children: [
      {
        path: '/freshlyBaked/bill/shopOrder/shopOrderDetail',
        component: () => import('@/views/freshlyBaked/bill/shopOrder/shopOrderDetail'),
        name: 'FreshlyBakedShopOrderDetail',
        meta: {
          title: '门店现烤订单明细',
          activeMenu: '/freshlyBaked/bill/shopOrder',
          type: 'add'
        }
      }
    ]
  },
  //裱花原料订货-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:order:materialOrder'],
    children: [
      {
        path: '/decoration/order/materialOrder/materialOrderDetail',
        component: () => import('@/views/decoration/order/materialOrder/materialOrderDetail'),
        name: 'DecorationMaterialOrderDetail',
        meta: {
          title: '裱花原料订货明细',
          activeMenu: '/decoration/order/materialOrder',
          type: 'add'
        }
      }
    ]
  },
  //现烤原料订货-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:order:materialOrder'],
    children: [
      {
        path: '/freshlyBaked/order/materialOrder/materialOrderDetail',
        component: () => import('@/views/freshlyBaked/order/materialOrder/materialOrderDetail'),
        name: 'FreshlyBakedMaterialOrderDetail',
        meta: {
          title: '现烤间原料订货明细',
          activeMenu: '/freshlyBaked/order/materialOrder',
          type: 'add'
        }
      }
    ]
  },
  //裱花报损-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:scrap'],
    children: [
      {
        path: '/decoration/bill/scrap/scrapDetail',
        component: () => import('@/views/decoration/bill/scrap/scrapDetail'),
        name: 'DecorationScrapDetail',
        meta: {
          title: '裱花报损明细',
          activeMenu: '/decoration/bill/scrap',
          type: 'add'
        }
      }
    ]
  },
  //现烤报损-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:scrap'],
    children: [
      {
        path: '/freshlyBaked/bill/scrap/scrapDetail',
        component: () => import('@/views/freshlyBaked/bill/scrap/scrapDetail'),
        name: 'FreshlyBakedScrapDetail',
        meta: {
          title: '现烤报损明细',
          activeMenu: '/freshlyBaked/bill/scrap',
          type: 'add'
        }
      }
    ]
  },
  //裱花调出明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:moveOut'],
    children: [
      {
        path: '/decoration/bill/moveOut/moveOutDetail',
        component: () => import('@/views/decoration/bill/moveOut/moveOutDetail'),
        name: 'DecorationMoveOutDetail',
        meta: {
          title: '裱花调出明细',
          activeMenu: '/decoration/bill/moveOut',
          type: 'add'
        }
      }
    ]
  },
  //现烤调出明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:moveOut'],
    children: [
      {
        path: '/freshlyBaked/bill/moveOut/moveOutDetail',
        component: () => import('@/views/freshlyBaked/bill/moveOut/moveOutDetail'),
        name: 'FreshlyBakedMoveOutDetail',
        meta: {
          title: '现烤调出明细',
          activeMenu: '/freshlyBaked/bill/moveOut',
          type: 'add'
        }
      }
    ]
  },
  //裱花调入-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:moveIn'],
    children: [
      {
        path: '/decoration/bill/moveIn/moveInDetail',
        component: () => import('@/views/decoration/bill/moveIn/moveInDetail'),
        name: 'DecorationMoveInDetail',
        meta: {
          title: '裱花调入明细',
          activeMenu: '/decoration/bill/moveIn',
          type: 'add'
        }
      }
    ]
  },
  //现烤调入-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:moveIn'],
    children: [
      {
        path: '/freshlyBaked/bill/moveIn/moveInDetail',
        component: () => import('@/views/freshlyBaked/bill/moveIn/moveInDetail'),
        name: 'FreshlyBakedMoveInDetail',
        meta: {
          title: '现烤调入明细',
          activeMenu: '/freshlyBaked/bill/moveIn',
          type: 'add'
        }
      }
    ]
  },
  //裱花盘点-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:check'],
    children: [
      {
        path: '/decoration/bill/check/checkDetail',
        component: () => import('@/views/decoration/bill/check/checkDetail'),
        name: 'DecorationCheckDetail',
        meta: {
          title: '裱花盘点明细',
          activeMenu: '/decoration/bill/check',
          type: 'add'
        }
      }
    ]
  },
  //现烤盘点-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:check'],
    children: [
      {
        path: '/freshlyBaked/bill/check/checkDetail',
        component: () => import('@/views/freshlyBaked/bill/check/checkDetail'),
        name: 'FreshlyBakedCheckDetail',
        meta: {
          title: '现烤盘点明细',
          activeMenu: '/freshlyBaked/bill/check',
          type: 'add'
        }
      }
    ]
  },
  //裱花盘盈明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:checkFull'],
    children: [
      {
        path: '/decoration/bill/checkFull/checkFullDetail',
        component: () => import('@/views/decoration/bill/checkFull/checkFullDetail'),
        name: 'DecorationCheckFullDetail',
        meta: {
          title: '裱花盘盈明细',
          activeMenu: '/decoration/bill/checkFull',
          type: 'add'
        }
      }
    ]
  },
  //现烤盘盈明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:checkFull'],
    children: [
      {
        path: '/freshlyBaked/bill/checkFull/checkFullDetail',
        component: () => import('@/views/freshlyBaked/bill/checkFull/checkFullDetail'),
        name: 'FreshlyBakedCheckFullDetail',
        meta: {
          title: '现烤盘盈明细',
          activeMenu: '/freshlyBaked/bill/checkFull',
          type: 'add'
        }
      }
    ]
  },
  //裱花盘亏明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['decoration:bill:checkLose'],
    children: [
      {
        path: '/decoration/bill/checkLose/checkLoseDetail',
        component: () => import('@/views/decoration/bill/checkLose/checkLoseDetail'),
        name: 'DecorationCheckLoseDetail',
        meta: {
          title: '裱花盘亏明细',
          activeMenu: '/decoration/bill/checkLose',
          type: 'add'
        }
      }
    ]
  },
  //现烤盘亏明细-新增
  {
    path: '/system/user-auth',
    component: Layout,
    hidden: true,
    permissions: ['freshlyBaked:bill:checkLose'],
    children: [
      {
        path: '/freshlyBaked/bill/checkLose/checkLoseDetail',
        component: () => import('@/views/freshlyBaked/bill/checkLose/checkLoseDetail'),
        name: 'FreshlyBakedCheckLoseDetail',
        meta: {
          title: '现烤盘亏明细',
          activeMenu: '/freshlyBaked/bill/checkLose',
          type: 'add'
        }
      }
    ]
  },
]

// 防止连续点击多次路由报错
const Router = VueRouter;
let routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(err => err)
}

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
